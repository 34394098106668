import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import configureStore from './store';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import i18n from './shared/i18n.js';
import Main from './Main.jsx';
import Config from 'config';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: (Config.mainColor || '#008AAE'),
    },
    secondary: {
      main: (Config.secondaryColor || '#FFF100')
    },
    accentBlue: '#1976d2',
    mediumBlue: '#006783',
    darkBlue: '#001F27',
    lightGray: '#efefef',
    gray: '#676767',
    darkGray: '#333333',
  },
  typography: {
    fontFamily: '"brandon", sans-serif'
  },
  overrides: {
    MuiInput: {
      focused: {
        border: 0
      },
      underline: {
        '&:after': {
          content: '',
        },
        '&:before': {
          content: '',
        },
      },
    },
  },
});
ReactDOM.render(
  <Provider store={configureStore()}>
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <Main i18n={i18n} history={history}/>
      </MuiThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('home')
);
